import React, { useEffect, useLayoutEffect, useState } from "react";
import ferrumFooterLogo from "../assets/img/ferrum-footer-logo.svg";
import socialIcon from "../assets/img/social-icon-1.svg";
import twitterIcon from "../assets/img/footer-twitter.svg";
import instaIcon from "../assets/img/footer-insta.svg";

export const Footer = () => {
  const [windowDimensionlogo, setWindowDimensionLogo] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [ferrumlogo, setFerrumLogo] = useState(false);
  useEffect(() => {
    if (windowDimensionlogo.width < 480) {
      setFerrumLogo(false);
    } else {
      setFerrumLogo(true);
    }
  }, [windowDimensionlogo]);
  useLayoutEffect(() => {
    const handleResize = () => {
      const { innerWidth: width, innerHeight: height } = window;

      setWindowDimensionLogo({ width, height });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {ferrumlogo && (
        <div className="d_flex justify_start align_center footer ">
          <div className="d_flex justify_start align_center w-100">
            <a href={"https://discord.gg/hypergpt"} target={"_blank"}>
              <img src={socialIcon} alt={socialIcon} />
            </a>
            <a href={"https://twitter.com/hypergpt"} target={"_blank"}>
              <img src={twitterIcon} alt={twitterIcon} />
            </a>
            {/* <a
              href={"https://www.instagram.com/chibidinosnft/"}
              target={"_blank"}
            >
              <img src={instaIcon} alt={instaIcon} />
            </a> */}
          </div>
          <div className="d_flex justify_end align_center">
            <a href={"http://ferrum.network/"} target={"_blank"}>
              <img src={ferrumFooterLogo} alt={ferrumFooterLogo} />
            </a>
          </div>
        </div>
      )}
      {!ferrumlogo && (
        <>
          <div className="d_flex justify_start align_center w-100 footer-logo  ">
            <a href={"https://discord.gg/hypergpt"} target={"_blank"}>
              <img src={socialIcon} alt={socialIcon} />
            </a>
            <a href={"https://twitter.com/hypergpt"} target={"_blank"}>
              <img src={twitterIcon} alt={twitterIcon} />
            </a>
            {/* <a
              href={"https://www.instagram.com/chibidinosnft/"}
              target={"_blank"}
            >
              <img src={instaIcon} alt={instaIcon} />
            </a> */}
          </div>
          <div className="footerferrum-logo">
            <a href={"http://ferrum.network/"} target={"_blank"}>
              <img src={ferrumFooterLogo} alt={ferrumFooterLogo} />
            </a>
          </div>
        </>
      )}
    </>
  );
};
function setUserToggler(arg0: boolean) {
  throw new Error("Function not implemented.");
}

function setOpenToggler(arg0: boolean) {
  throw new Error("Function not implemented.");
}

function setWindowDimension(arg0: { width: number; height: number }) {
  throw new Error("Function not implemented.");
}
