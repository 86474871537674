import React, { useEffect, useState } from "react";
import Lander from "./pages/lander";
import { Route, Switch, useLocation, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import * as AppActions from "./redux/app-contract/appContractActions";
import { RootState } from "./redux/rootReducer";
import { signInUser } from "./_apis/vesting";
import toast, { Toaster } from "react-hot-toast";
import { setSmartContractAddress } from "../src/utils/const.utils";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentRouteContractAddress: any = location.pathname.split("/").pop();
  const mainContractAddress = useSelector(
    (state: RootState) => state.mainAppContract.mainContract
  );
  const [smartContractAddressParam, setSmartContractAddressParam] =
    useState("");
  const history = useHistory();

  useEffect(() => {
    console.log(location.search);
    const urlParams = new URLSearchParams(location.search);
    let sParam: string = urlParams.get("smartContractAddress") ?? "";
    if (!sParam) {
      history.push(
        "/user/dashboard/0x529C79f6918665EBE250F32eeEAA1d410a0798C6?smartContractAddress=0xe08af0b4F8c860D3523E176724Bb2bCC54f3bC04"
      );
    } else {
      let sParamInToLower = sParam.toLocaleLowerCase();
      if (sParamInToLower == "0x3655bf5fda0551d69e91f51a0ffa921d05bffd47") {
        history.push(
          "/user/dashboard/0x529C79f6918665EBE250F32eeEAA1d410a0798C6?smartContractAddress=0xe08af0b4F8c860D3523E176724Bb2bCC54f3bC04"
        );
      }
    }
    setSmartContractAddress(sParam);
    setSmartContractAddressParam(sParam);
    console.log("smartContractAddress", sParam ?? "");
    dispatch(AppActions.contractAddressOfApp(currentRouteContractAddress));
  });

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      {smartContractAddressParam && (
        <Switch>
          <Route path={`/`} component={Lander}></Route>
        </Switch>
      )}
    </>
  );
}

export default App;
